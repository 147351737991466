




















































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import BaseMenu from './BaseMenu.vue';
import BaseUserAvatar from './BaseUserAvatar.vue';
import BasePill from './BasePill.vue';

@Component({
  name: 'BaseUserCard',
  components: {
    BaseMenu,
    BaseUserAvatar,
    BasePill
  }
})
export default class BaseUserCard extends Vue {
  @Prop({ default: null }) readonly user!: User;

  @Prop({ default: () => [] }) readonly menuOptions!: Array<MenuOption>;

  @Getter allTeamsById!: (id: string) => Team;

  get userUnitName(): string {
    if (!this.user) {
      return '';
    }
    const unit = this.user.unit && this.allTeamsById(this.user.unit);
    return unit ? `${unit.name}` : '';
  }

  get userName(): string {
    return this.user.firstName && this.user.lastName
      ? `${this.user.firstName} ${this.user.lastName}`
      : `${this.user.email}`;
  }

  onMenuOptionClick(option: MenuOption): void {
    this.$emit('onMenuSelect', option);
  }
}
