





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import User from '@improve/common-utils/src/model/User';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import BaseUserCard from '../../widgets/BaseUserCard.vue';
import BaseUserSkeletonCard from '../../user/BaseUserSkeletonCard.vue';

@Component({
  name: 'GenericUserList',
  components: {
    BaseUserCard,
    BaseUserSkeletonCard
  }
})
export default class GenericUserList extends Vue {
  @Prop({ default: () => [] }) readonly users!: Array<User>;

  @Prop({ default: false }) readonly searchInProgress!: boolean;

  @Prop({ default: null }) readonly getCardMenuOptions!: (u: User) => Array<MenuOption>;
}
