

































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'BaseUserSkeletonCard'
})
export default class BaseUserSkeletonCard extends Vue {
  @Prop({ default: true }) readonly loading!: boolean;
}
